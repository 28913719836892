/*
    init.js v2.0
    Wezom wTPL v4.0.0
*/
window.wHTML = (function($){

    /* Приватные переменные */

        var varSeoIframe = 'seoIframe',
            varSeoTxt = 'seoTxt',
            varSeoClone = 'cloneSeo',
            varSeoDelay = 200;

    /* Приватные функции */

        /* проверка типа данных на объект */
        var _isObject = function(data) {
            var flag = (typeof data == 'object') && (data+'' != 'null');
            return flag;
        },

        /* создание нового элемента элемента */
        _crtEl = function(tag, classes, attrs, jq) {
            var tagName = tag || 'div';
            var element = document.createElement(tagName);
            var jQueryElement = jq || true;
            // если классы объявлены - добавляем
            if (classes) {
                var tagClasses = classes.split(' ');
                for (var i = 0; i < tagClasses.length; i++) {
                    element.classList.add(tagClasses[i]);
                }
            }
            // если атрибуты объявлены - добавляем
            if (_isObject(attrs)) {
                for (var key in attrs) {
                    var val = attrs[key];
                    element[key] = val;
                }
            }
            // возвращаем созданый елемент
            if (jQueryElement) {
                return $(element);
            } else {
                return element;
            }
        },

        /* создаем iframe для сео текста */
        _seoBuild = function(wrapper) {
            var seoTimer;
            // создаем iframe, который будет следить за resize'm окна
            var iframe = _crtEl('iframe', false, {id: varSeoIframe, name: varSeoIframe});
            iframe.css({
                'position':'absolute',
                'left':'0',
                'top':'0',
                'width':'100%',
                'height':'100%',
                'z-index':'-1'
            });
            // добавляем его в родитель сео текста
            wrapper.prepend(iframe);
            // "прослушка" ресайза
            seoIframe.onresize = function() {
                clearTimeout(seoTimer);
                seoTimer = setTimeout(function() {
                    wHTML.seoSet();
                }, varSeoDelay);
            };
            // вызываем seoSet()
            wHTML.seoSet();
        };

    /* Публичные методы */

        function Methods(){}

        Methods.prototype = {

            /* установка cео текста на странице */
            seoSet: function() {
                if ($('#'+varSeoTxt).length) {
                    var seoText = $('#'+varSeoTxt);
                    var iframe = seoText.children('#'+varSeoIframe);
                    if (iframe.length) {
                        // если iframe сущствует устанавливаем на место сео текст
                        var seoClone = $('#'+varSeoClone);
                        if (seoClone.length) {
                            // клонеру задаем высоту
                            seoClone.height(seoText.outerHeight(true));
                            // тексту задаем позицию
                            seoText.css({
                                top: seoClone.offset().top
                            });
                        } else {
                            // клонера нету - бьем в колокола !!!
                            console.error('"'+varSeoClone+'" - не найден!');
                        }
                    } else {
                        // если iframe отсутствует, создаем его и устанавливаем на место сео текст
                        _seoBuild(seoText);
                    }
                }
            },

            /* magnificPopup inline */
            mfi: function() {
                $('.mfi').magnificPopup({
                    type: 'inline',
                    closeBtnInside: true,
                    removalDelay: 300,
                    mainClass: 'zoom-in'
                });
            },

            /* magnificPopup ajax */
            mfiAjax: function() {
                $('body').magnificPopup({
                    delegate: '.mfiA',
                    callbacks: {
                        elementParse: function(item) {
                            this.st.ajax.settings = {
                                url: item.el.data('url'),
                                type: 'POST',
                                data: (typeof item.el.data('param') !== 'undefined') ? item.el.data('param') : ''
                            };
                        },
                        ajaxContentAdded: function(el) {
                            wHTML.validation();
                        }
                    },
                    type: 'ajax',
                    removalDelay: 300,
                    fixedContentPos: true,
                    fixedBgPos: false,
                    mainClass: 'zoom-in'
                });
            },

            /* оборачивание iframe и video для адаптации */
            wTxtIFRAME: function() {
                var list = $('.wTxt').find('iframe').add($('.wTxt').find('video'));
                if (list.length) {
                    // в цикле для каждого
                    for (var i = 0; i < list.length; i++) {
                        var element = list[i];
                        var jqElement = $(element);
                        // если имеет класс ignoreHolder, пропускаем
                        if (jqElement.hasClass('ignoreHolder')) {
                            continue;
                        }
                        if (typeof jqElement.data('wraped') === 'undefined') {
                            // определяем соотношение сторон
                            var ratio = parseFloat((+element.offsetHeight / +element.offsetWidth * 100).toFixed(2));
                            if (isNaN(ratio)) {
                                // страховка 16:9
                                ratio = 56.25;
                            }
                            // назнчаем дату и обрачиваем блоком
                            jqElement.data('wraped', true).wrap('<div class="iframeHolder ratio_' + ratio.toFixed(0) + '" style="padding-top:'+ratio+'%;""></div>');
                        }
                    }
                    // фиксим сео текст
                    this.seoSet();
                }
            }
        };

    /* Объявление wHTML и базовые свойства */

    var wHTML = $.extend(true, Methods.prototype, {});

    return wHTML;

})(jQuery);




jQuery(document).ready(function($) {

    // поддержка cssanimations
    transitFlag = Modernizr.cssanimations;

    // очитска localStorage
    localStorage.clear();

    // сео текст
    wHTML.seoSet();

    // magnificPopup inline
    wHTML.mfi();

    // magnificPopup ajax
    wHTML.mfiAjax();

    // валидация форм
    wHTML.validation();


    $(window).load(function() {
        // оборачивание iframe и video для адаптации
        wHTML.wTxtIFRAME();


        // min-height
        // var hRight =  $('.wRight').height();
        // if($('.wRight').length) {
        //     $('.wMiddle').css('min-height', hRight)
        // }

    /** ### js-partners__slider
     Инициализация слайдера carouselFred (js-partners__slider), если он существует
     * @name WDOCS_FROM
    */

    if ($('.js-partners__slider').length) {

        $('.js-partners__slider').carouFredSel({
                // items: 5,
                width: "100%",
                items: {
                    // visible: 7,
                    height: 'auto'
                },
                // height: 300, 
                auto: {
                    play: false,
                    timeoutDuration: 3000
                },
                prev: ".js-nav__prev",
                next: ".js-nav__next",
                swipe: {
                    onTouch: true
                },
                scroll: {
                    items: 1,
                    fx: "scroll",                   
                    duration: 1000,
                    pauseOnHover: true            
                },
            }, {
                transition: transitFlag

            });

    };
    //WDOCS_TO

    /** ### js-wTop_slider
     Инициализация слайдера carouselFred (js-wTop_slider), если он существует
     * @name WDOCS_FROM
    */
    if ($('.js-wTop_slider').length) {

        $('.js-wTop_slider').carouFredSel({
            items: {},
            width: 'auto',
            // height: 384,
            responsive: true,
            auto: {
                play: false,
                timeoutDuration: 5000
            },
            prev: '.js-wTop_slider_prev',
            next: '.js-wTop_slider_next',
            swipe: {
                onTouch: true
            },
            scroll: {
                items: 1,
                fx: "directscroll",                   
                duration: 1200,
                pauseOnHover: true
            },
        }, {
            transition: transitFlag

        });
    }
    //WDOCS_TO

    //accordeon
    $(".wRight__list > p").first().addClass('curr').next('ul').show();
    $(".wRight__list > p").on('click', '.svgHolder', function() {        
        if ($(this).parent('p').hasClass('curr')) {
            $(this).parent('p').removeClass('curr').next('ul').stop().slideUp(400);
        } else {
            $(this).parent('p').addClass("curr").next('ul').stop().slideDown(400);
            $(this).closest('.wRight__list').siblings('.wRight__list').children('p').removeClass('curr').next('ul').slideUp(400);
        }
    });

    //show_text
    if($('.js-btn_show_text').length){
        $('.js-btn_show_text').on('click', function(){
            $(this).toggleClass('replace').parent('.wBlock_content').toggleClass('show');
            // $('.js-btn_show_text.replace').children('span').text('Скрыть текст')
        })
    }

    });

    /** ### Mmenu
         Инициализация Mmenu
         * @name WDOCS_FROM
        */   
        $("#js-menu").mmenu({
            "offCanvas": {
              "position": "right"
           },
           "extensions": [
              "theme-dark"
           ],
           counters: true,
            dividers: {
                add: true,
                addTo: "[id*='contacts-']",
                fixed: true
            },
            // searchfield: {
            //  resultsPanel: true
            // },
            navbar: {
                title: "Меню"
            },
            navbars: {
                height: 4,        
                position: 'bottom',
                content:  [ 
                    '<div class="wHeader__top--right">'+
                    '<ul class="wHeader__social">'+
                    '<li><a href="#" class="soc_fb"><img src="" alt=""><span>Facebook</span></a></li>'+
                    '<li><a href="#" class="soc_vk"><img src="" alt=""><span>Vkontakte</span></a></li>'+
                    '</ul>'+
                    '<ul class="wHeader__phones">'+
                    '<li><a href="tel:+380502582995">+38 050 258 29 95</a></li>'+
                    '<li><a href="tel:+380672582995">+38 067 258 29 95</a></li>'+
                    '<li><a href="tel:+380682582995">+38 068 258 29 95</a></li>'+
                    '</ul>'+
                    '</div>'
                ]
            }
        }); 
        //WDOCS_TO

        //+ + получаем api плагина
        var api_mmenu = $('#js-menu').data('mmenu');

        //+ + реагирование на закрытие
        api_mmenu.bind('closed', function () {
            api_mmenu.closeAllPanels();
        });


        /** ### GoogleMap
         Инициализация GoogleMap, если он существует
         * @name WDOCS_FROM
        */ 
        if ($("#ggl_map").length) {
            var jsonMap,
                build,
                objectsInKey,
                map,
                mapUrl = '/Media/jsons/map_'+$('html').attr('lang')+'.json';
            $.getJSON( mapUrl, function( data ) {
                jsonMap = data;
                loadGoogleMap();           

            });
            function loadGoogleMap() {
                var myOptions = {
                    zoom: jsonMap.maps.zoom,
                    center: jsonMap.maps.LatLng,
                    scrollwheel: false,
                    zoomControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    scrollwheel: true,
                    panControl: false,
                    mapTypeControl: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };
                map = new google.maps.Map(document.getElementById("ggl_map"), myOptions);
                objectsInKey = jsonMap.maps.markers;
                build = Object.keys(jsonMap.maps.markers).length;
                for (var i = 0; i < build; i++) {
                    var marker = new google.maps.Marker({
                        position: objectsInKey[i].LatLng,
                        map: map,
                        icon: jsonMap.maps.icon,
                        title: objectsInKey[i].name
                    });
                }
            }
           
        }
        //WDOCS_TO

        /** ### mCustomScrollbar
         Инициализация mCustomScrollbar, если он существует
         * @name WDOCS_FROM
        */ 
        if ($(".Calendar__table").length) {
            $('.Calendar__table').mCustomScrollbar();
        }
        //WDOCS_TO

        if ($('.wRight').length) {
            var height_ = $('.wRight').height();
            $('.wMiddle').css('min-height', height_ );
        }



        //scroll_top
        // var top_show = 100;
        // // var delay = 1000;

        // $(window).scroll(function() {
        //     if ($(this).scrollTop() > top_show) {
        //         $('.wHeader__bottom').addClass('p_fixed');
        //     }
        //     else $('.wHeader__bottom').removeClass('p_fixed');
        // });


});
